<template>
  <div class="order">
    <div class="order__left">
      <div class="order__left--head">
        <el-tabs v-model="activeName" @tab-click="tabClick">
          <el-tab-pane
            :label="item.label"
            :name="item.value"
            :key="item.value"
            v-for="item in areaLs"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <div class="order__left--body">
        <div class="order__left--body__table" v-loading="loading">
          <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            height="100%"
            @row-click="rowClick"
          >
            <el-table-column prop="orderSerialNum" label="订单编号">
            </el-table-column>
            <el-table-column prop="createTime" label="下单时间" >
            </el-table-column>
            <el-table-column label="订单状态" >
              <template slot-scope="scope">
                <div>
                  {{ orderStatus[scope.row.orderStatus] }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="actualPrice" label="金额"> </el-table-column>
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <div @click="print(scope.row)" class="printButton">打印</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="order__left--body__pagination">
          <el-pagination
            :page-size="pageData.pageSize"
            :current-page.sync="pageData.pageNum"
            @current-change="getPage"
            background
            layout="prev, pager, next"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <transition name="el-fade-in">
      <div class="order__right">
        <orderData
          :orderSee="true"
          description="点击订单查看详情~"
          :orderLs="orderLs"
          @invoicing="invoicing"
          @cancelOrder="cancelOrder"
          @cleanMachine="cleanMachine"
        />
      </div>
    </transition>
  </div>
</template>
<script>
import orderData from '@/components/orderData/index.vue'
import orderInfo from '@/service/orderInfo.js'
import uniPay from '@/utils/pay.js'
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    orderData
  },
  data () {
    return {
      activeName: '0',
      areaLs: [
        { label: '未完成', value: '0' },
        { label: '全部', value: '2' },
        { label: '已完成', value: '1' }
      ], // 区域列表
      orderLs: [],
      loading: false, // 是否加载中,
      tableData: [], // 订单数据
      orderStatus: {
        0: '未支付',
        1: '已支付',
        2: '已过期',
        3: '已完成',
        4: '已取消',
        5: '已退款'
      },
      pageData: {
        pageNum: 1,
        pageSize: 20,
        total: 0
      }
    }
  },
  computed: {
    ...mapState({
      shopInfo: (store) => store.login.shopInfo
    })
  },
  mounted () {
    this.$eventBus.$on('invoicing', () => this.invoicing())
    this.getPage()
  },
  beforeDestroy () {
    this.$eventBus.$off('invoicing')
  },
  methods: {
    ...mapMutations({
      setNewMsg: 'login/setNewMsg'
    }),
    tabClick () {
      this.confirm()
    },
    // 重置数据并且重新请求桌台数据
    confirm () {
      this.finished = false
      this.loading = false
      this.tableData = []
      this.status = 'loadmore'
      this.pageData.pageNum = 1
      this.getPage()
    },
    // 获取商户桌台列表
    async getPage () {
      this.loading = true
      const formData = {
        businessId: this.shopInfo.id,
        orderStatus: this.activeName === '2' ? '' : this.activeName,
        pageSize: this.pageData.pageSize,
        pageNum: this.pageData.pageNum
      }
      const res = await orderInfo.orderInfoPageOrder(formData)
      this.tableData = res.data.list
      this.pageData.total = res.data.total
      this.loading = false
    },
    cancelOrder (item) {
      const str = item.payType !== 4 ? '是否取消订单并退款' : '是否取消订单并退还现金'
      const cancelStr = item.orderStatus === 1 ? str : '是否确认取消订单'
      this.$confirm(cancelStr, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
         this.loading = true
        // 微信支付，并且已经支付
        if (item.orderStatus === 1 && item.payType !== 4) {
          const refundRes = await uniPay.refund({
            outTradeNo: item.outTradeNo,
            actualPrice: item.actualPrice,
            orderInfoId: item.id,
            orderInfo: item
          })
          if (!refundRes.success) {
            this.loading = false
            return
          }
        }
        this.loading = false
        this.refundOrder(item)
      })
    },
    // 清台
    async cleanMachine (item) {
      const confirm = await this.$confirm('客户已结账,是否确定清台?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      // 已经退还现金，修改订单状态
      if (confirm === 'confirm') {
        const res = await orderInfo.changeOrderStatus({
          id: item.id,
          orderStatus: 3
        })
        if (res.success) {
          this.$message({
            message: '订单已经完成',
            type: 'success'
          })
          this.orderLs = []
          this.getPage()
        }
      }
    },
    invoicing () {
      this.$message({
        message: '订单已经完成',
        type: 'success'
      })
      this.orderLs = []
      this.getPage()
    },
    async refundOrder (item) {
      const res = await orderInfo.changeOrderStatus({
        id: item.id,
       orderStatus: item.orderStatus !== 1 ? 4 : 5
      })
      if (res.success) {
        this.$message({
          message: '订单取消成功',
          type: 'success'
        })
        this.printOrder({
          type: item.orderStatus === 1 ? 2 : 1,
          orderId: item.id,
          voice: 0
        })
        this.orderLs = []
        this.getPage()
      }
    },
    rowClick (e) {
      console.log(e)
      this.orderLs = [e]
    },
    print (item) {
      this.printOrder({
        orderId: item.id,
        type: 2,
        voice: 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.order {
  height: 100%;
  display: flex;
  &__left {
    height: 100%;
    background: #ffffff;
    border-radius: 24px;
    flex: 1;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    transition: all 0.3s;
    &--head {
      padding: 0px 20px;
      box-sizing: border-box;
      ::v-deep .el-tabs__item {
        height: 87px !important;
        line-height: 87px !important;
        color: #000000;
        font-weight: 700;
        font-size: 20px;
        width: 154px;
        text-align: center;
        padding: 0px !important;
      }
      ::v-deep .el-tabs__active-bar {
        width: 154px !important;
        background-color: #ffbb12 !important;
      }
    }
    &--body {
      flex: 1;
      min-height: 0px;
      width: 100%;
      overflow: auto;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      &__table {
        flex: 1;
        min-height: 0px;
      }
      &__pagination {
        text-align: right;
        margin-top: 20px;
      }
    }
  }
  &__right {
    width: 671px;
    height: 100%;
    margin-left: 56px;
  }
}
::v-deep .el-tabs__nav-next{
  line-height: 87px !important;
}
::v-deep .el-tabs__nav-prev{
  line-height: 87px !important;
}
.printButton {
  background: #ffbb12;
  border-radius: 4px;
  width: 51px;
  height: 27px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #000000;
}
</style>
